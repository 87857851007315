.sous-bloc {
    //width: 80%;
    //max-width: 1600px;
    flex-direction: column;
    //margin: auto; // utile
    align-items: center; // utile
    display: flex; // utile
    ////justify-content: center; sert a rien
    ////background-color: #93ad18;
    form {min-width: 940px;}
    padding-top: 15px; // 20 avant le 3108
}
.sous-bloc2 {
    flex-direction: column;
    display: flex; // utile
    //background-color: #93ad18;
    min-width: 940px;
    padding-top: 20px;

    .sous-partie{
      padding-bottom: 30px;
    }
}

.partie-form {
  width:100%;
  flex-direction:row;
  display: flex;

  input, select { 
    margin : 0px 5px 10px;
    width:300px;
  }
  .classLabelRed { 
    //font-size: 1.1em;
    color: #ec6a69;
  }
}
.partie-form-right {
  justify-content: flex-end;
  .form-check-input {
    width: 30px !important; 
    height: 13px !important;
    margin-top: 5px;
    /*background-color: $color-button-entourage !important;
    border : 1px $color-button-entourage solid !important;*/
  }
  label {
    font-weight: bold; 
    font-size: 1.2em;
  }
}
.partie-form-bt {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items : center;

  margin : 5px 0px 15px;
  //.partie-form-bt-info {padding-top: 10px;}
  button, a { margin : 0 15px ;}
  a {
    //padding-top: 5px;
    font-size: 1.4em;
    font-weight: bold;
  }
}

.partie-bouton-crea {
  flex-direction:row;
  display: flex;
  justify-content: center;
  button, a { margin : 10px 15px 30px;}
}
.partie-bouton-droite {
  margin: 10px 0;
  flex-direction:row;
  display: flex;
  justify-content: flex-end;;
  button, a { margin : 0px }
}


///////// partie page de détail
.ariane {
  //width: 100%;
  border-left: 2px $color-bord-gauche-bloc solid;
  //margin : 10px 0 0;
  padding-left: 5px;
  font-size: 1.1em;

  .ariane-titre{
    font-size: 1.2em;
  }
  .ariane-sous-titre{
    font-size: 1.05em;
  }
  /* inspi left-menu-nav-itemOn ,  utilisé dans le fil d'ariane*/
  .ariane-fleche {
    color: $color-liensOn !important;
    text-decoration: none;
    margin-right: 0.5rem;
    padding-left: 10px;
    
    cursor: pointer;
    .fas {
      //display: inline; // était utile pour faire apparaitre curseur au depart?
      //@at-root: visible;
      padding:0; // important : pas juste padding right car comme on fait un rotate ca fait un decalage iunferieur...
      transform: rotate(45deg);
      color: $color-liens-arrowMenuLeft;
      font-size: 1em;
    }
  }
}

.fixe-taille-max-large {
    max-width: 1270px;
}
.section-generale { /* copie de .certifications-recherche */
  max-width: 980px;
  border-left: 2px $color-bord-gauche-bloc solid;
  padding-left: 5px;
  margin-top: 20px;
  .titre {
    font-weight: bold;
  }

  .input-wrapper {
    padding-bottom :20px;
  }
}
.titre-inscription, .titre-inscription-separation, .titre-inscription-separation-important {
  font-weight: bold;
  font-size:  1.4em;
  color: $color-aide-background;
  padding: 0;
  margin-bottom : 10px;
  border-bottom : $color-aide-background solid 1px;
}
.titre-inscription-separation, .titre-inscription-separation-important {
  padding: 10px 0 0;
}
.titre-inscription-separation-important {
  color: $color-fond-top-menu;
  font-weight: bolder;
}

.edit-form {
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap; //les blocs iront à la ligne si plus la place.. pas bon si par exemple lecture detail certif
  margin-top:10px; // 15 avant le 3108

  .corps-form {
    min-width: 940px; // verif le 141223 : le fait de le commenter induit un décalage au chargement de l'étape 4 par exemple, a éviter donc !
    /*border-top: 1px gray solid;
    padding-top:5px;*/
  }
}

#champRechQuiformeTypeDiff option:nth-child(2) {  color: green;} // pour colorer les différent libellé des options d'un select
#champRechQuiformeTypeDiff option:nth-child(3) {  color: orange;}
#champRechQuiformeTypeDiff option:nth-child(4) {  color: blue;}

.alignement-horizontal-champs-form {
  display:inline-flex;
  padding-right: 20px;
  vertical-align: top; // bottom avant, middle le 2308 pour champ tel de edition-cpf, top depuis le 0509 pour inscription quand un champ oblig en ligne etait pas rempli, a verif si ok partout
  .mb-3{ width:205px;}
}

.form-quiforme { // ajout pour Quiforme : conteneur principal
  max-width: 1000px !important; // utile car la liste historique quie st dans le meme conteneurpeut etre très large;
  .form-label{width: 180px;} // intitulé du champ à gauche
  .form-text{padding-left: 5px;}  //infos caractères restants 
}
.alignement-horizontal-champs-form2 {   // ajout pour Quiforme
  display: block ruby; // ok aussi avec flex
  .complementInfo {padding-left: 10px;} //info Quiforme 
}

//.complementInfo{padding-left:2px;}
.red{color: red;}
.green{color: green;}
.orange{color: orange;}

// important pour texte aligné sur la gauche dans les bulles d'aide Tooltip de bootstrap
.tooltip-inner {
  //margin-left : 100px;
  text-align: left !important;
  background-color: $color-aide-background !important;
  color: black !important;
  font-size: 1.4em;
  font-family: 'Barlow-Regular' !important; // Regular ou SemiBold 
  border : 1px grey solid;
  border-radius: 10px;;
}
.tooltip.show {opacity:1!important;}

// pour colorer la fleche du selectbox
.custom-select {
  //https://stackoverflow.com/questions/46836406/bootstrap-4-how-to-amend-custom-select-arrow-to-change-colour
  // voir aussi un style arrondi plus poussé : https://stackoverflow.com/questions/71776359/how-to-change-colour-of-select-dropdown-arrow
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='%23ec6a69' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") right .75rem center/8px 10px no-repeat white !important;
  //background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='red' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")  no-repeat right .75rem center/8px 10px !important
  //background: #FFFFFF url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='red' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;

  font-size: 1.1em !important;
}

.form-rech-ci {
  flex-direction: column;
  align-items: center;
  display: flex;
  width:100%; //attention form a un min-with .sous-bloc form
}

.partie-form-rech-ci {
  //width:100%;
  text-align: center;
  align-items: center;
  flex-direction:row;
  display: flex;
  //background-color: pink;
  flex-wrap: wrap;
  input, select {
    margin : 0px 5px 10px;
  }

  /*.lib:required { a tester plus tard pour colorer un champ marqué required
    color: RED;
  }*/
  select { 
    width:228px;
  }
}
// ces 3 vont ensembles
.centrage-deux-elements {
  text-align: center;
  align-items:center; // last baseline before
  flex-direction:row;
  display: flex;
  justify-content: center;

  input, select {
    margin : 0px 5px 10px;
  }
}
.align-div-right {
  text-align: right;
  margin-right: 10px;;
}
.align-div-left {
  text-align: left;
}

// 2 ci dessous sortis de .partie-form-rech-ci pour profiter aux autres formulaires...
  .input-codes { 
    width:200px !important;
  }
  .input-lib { 
    width:380px !important; /* important obligatoire après avoir mis bootstrap */
  }

  .input-codes-2ch { 
    width:60px !important;
  }
  .input-codes-5ch {
    width:150px !important;
  }
  .input-date-inline {
    width: 110px !important;
    display : inline !important;
  }

  // ajout input-inline .st .mt pour Quiforme
  .input-inline {
    display : inline !important;
    margin-left: 10px;
  }
  .st {width: 110px !important; }
  .mt {width: 320px !important; }

.partie-form-rech-ci-bt {
  //align-self: flex-end; /* Seul ce bloc sera aligné à la fin */
  //button {margin-right:180px;}
  display:flex; // inline-flex jusqu'au 270123
  .form-check {margin: 15px 50px 0 0}

}

.alert-input {
  background-color: orangered !important;
}

.list-bloc {
  li {  padding-bottom: 4px; }
}

.message-carif {
  margin-top : 15px;
  border : 1px $color-aide-alert  solid;
  border-radius: 5px;;
  padding: 10px 10px 0;
  background-color: #fefcfc;
  width : 100%
}