.left-menu {
  .in {
    padding: 10px 5px;
    ul { padding-left: 0; font-weight: bold;} //nok bootstrap applique un padding left de 2rem de base
    li {  padding-top: 10px; }
  }

  /*position: fixed;
  top: 0;
  background-color: $color-background-menu;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  width: 9rem;
  height: 100%;   */ 

  border-radius: 10px;
  background-color: $bloc-background;
  padding: 10px 5px 0 0;
  margin-right: 6px;
  min-width: 200px;

  .left-menu-nav-item {
      text-decoration: none;
      margin-right: 0.5rem;
      .fas {
        //display: none; // important pour ne pas faire apparaitre curseur
        visibility: hidden;
      }
  }
  .left-menu-nav-itemOn {
    color: $color-liensOn !important;
    text-decoration: none;
    margin-right: 0.5rem;

    cursor: pointer;
    .fas {
      //display: inline; // était utile pour faire apparaitre curseur au depart?
      //@at-root: visible;
      padding: 2px 2px 0 0; // important : pas juste padding right car comme on fait un rotate ca fait un decalage iunferieur...
      transform: rotate(45deg);
      color: $color-liens-arrowMenuLeft;
      
    }
  }
    
  .left-menu-nav-item:hover {
    text-decoration: underline;
  }
}

.fa-circle-check {  color: $color-picto-validation;}
.nogood {  color: grey !important;}
//.fa-circle-exclamation {  color: $color-alert-font !important;} pas utilidé pour le moment

.etat-form-nok{
  color: $color-alert-font;
  font-weight: bold;
}
.etat-form-ok{
  color: $color-picto-validation ;
  font-weight: bold;
}

