.logo-login {
    position: absolute;
    left : 10px;
    top: 10px;

    .feature-icon {
        max-height: 90px;
    }
}


.sign-in-titre {
    font-size: 3em;
    font-family: 'Barlow-SemiBold';
    text-align: center;
}

.sign-in-content {  
    box-sizing: border-box;
    width: 530px;
    margin: 0 auto;
    padding: 3rem 2rem;
    div { padding-top: 10px;}
}

.image-presentation {
    position: absolute;
    right : 10px;
    top: 10px;

    .feature-presentation {
        max-height: 400px;
    }
}
