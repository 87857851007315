.certifications-recherche, .professionnalisants-recherche, .certifications-associees, .detail-bloc-fiche 
.actions-associees, .lieux-associes, .contacts-associes, .organismes-associes, .ajout-lieux, .ajout-coord
{
    max-width: 980px;
    border-left: 2px $color-bord-gauche-bloc solid;
    padding-left: 5px;
    margin-top: 20px;
    .titre {
      font-weight: bold;
      padding-bottom:12px;
    }
}

.titre-rech-certif {
  font-weight: bold;
  padding-bottom:12px;
}
.titre-rep-certif {
  font-weight: bold;
  font-size: 13px;
  padding-left: 12px;
  margin: 0;
}
  