/// ensemble
.detail-bloc-fiche {
    // div { padding-left: 10px; }
     padding-left: 10px;
    // padding-bottom: 10px; embettant mour "mon organisme"
    div {
      /*:first-child {font-weight: bold;}
      :last-child {padding-bottom: 10px;}*/
      .intitule-detail-fiche { 
        font-weight: bold;
        color: $color-aide-background; //$color-fond-top-menu
      }
      .intitule-detail-fiche2 { 
        font-weight: bold;
        color: black; //$color-fond-top-menu
      }
    }
  }
  .titres-liste {
    font-weight: bold;
    color: $color-aide-background;
  }
  .titres-liste-big {
    font-weight: bold;
    font-size: 1.2rem;
    color: $color-fond-top-menu;
  }
  .element-detail{padding-bottom: 4px;}
  /// ensemble fin