.react-table {
    //.fas {color: $color-button-text;}
    .fas {color: $color-button-entourage;}
    //.lib { text-decoration: none !important; }
    .lib:hover { color: red !important; }
    .center-col { text-align: center; }
    .infos-secondaires { color: grey }
}
  
.react-table-pagination {
    padding: 0.5rem;
    //width: 100%;
    min-width: 940px; //980px;
    text-align: right;
    
    button {
    font-size: 1em; 
    background-color: transparent !important;
    border: 2px solid $color-button-entourage;
    border-radius: 10px;
    color: $color-button-text !important;
    padding: 0px 12px;
    font-weight: bold;
    text-decoration: none;

    }
    :disabled { /*on gris si c'est désactivé*/
      color: grey !important;
      border: 2px solid grey;
    }
    button:hover {
    border-color: $color-button-text;
    //cursor: pointer;
    }
    .infoPagination {
    padding: 15px;
    }

}


.button-list-ajout, .button-list-ajout2, .button-list-ajout-in-lib {
    .fas {color:  rgb(1, 50, 100) !important;} // fait aussi ci dessus avec ; $color-button-entourage...

    font-size: 1em;
    background-color: white !important; // transparent avant le 100124
    border: 2px solid #ec6a69;
    border-radius: 10px;
    color: rgb(1, 50, 100) !important;
    padding: 1px 3px;
    font-weight: bold;
    text-decoration: none;
  }
  .button-list-ajout2 {
    padding: 1px 4px;
    border: 2px solid grey;
  }
  
  .button-list-ajout:hover, .button-list-ajout2:hover {
    cursor: pointer;
    border-color: rgb(1, 50, 100) !important;
  }
  .disabled { color: #c0c0c0  !important;}
  .enrobage-boutton {
    padding-left: 6px;
  }
  /*.lib:hover .fas, .enrobage-boutton:hover .fas {
    color: $color-button-background-hover;
    cursor: pointer;
  }*/
  .lib:hover, .enrobage-boutton:hover { // 12/08 : remplace normalement avantageusement la classe ci dessus car les .fas n'était pas pris en compte
    :first-child {
      color: $color-button-background-hover;
      cursor: pointer;
    }
  }
// indentation pour les formacodes (et leftMenu)
  .position_arborescence-1 {padding-left: 10px;}
  .position_arborescence-2 {padding-left: 20px;}
  .position_arborescence-3 {padding-left: 30px;}
  .position_arborescence-3-5 {padding-left: 35px;} // pour mLEftMenu
  .position_arborescence-3-5b {padding-left: 35px; padding-top: 2px;} // pour mLEftMenu
  .position_arborescence-4 {padding-left: 40px;}
  .position_arborescence-5 {padding-left: 50px;}
  .position_arborescence-5-5 {padding-left: 55px;} // pour mLEftMenu
  .position_arborescence-5 {padding-left: 60px;}
  .position_arborescence-6 {padding-left: 70px;}
  .position_arborescence-7 {padding-left: 80px;}
