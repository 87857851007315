// myside https://www.cariforef-provencealpescotedazur.fr/Page/professionnalisation-2022
// mybtn https://www.cariforef-provencealpescotedazur.fr/Page/extranet-organisme-formation

.myside, .myside2 {
    //width: 90%;
    font-size: 1.4em; //18px
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: transparent !important;
    border: 2px solid $color-button-entourage;
    border-radius: 100px;
    margin: 10px auto 20px auto;
    color: $color-button-text !important;
    padding: 0px 10px;
    font-weight: bold;
    text-decoration: none;

    .fas {
        background-color: #FFFFFF;
        color: $color-button-entourage;
        font-size: 1.6em; 
        border-radius: 100px;
        padding: 4px 1px;
        margin-left: 10px;
    }
    .sd {
        color: grey;
    }
    &:hover .fas {
        color: $color-button-background-hover;
    }

    // lorsque le bouton est desactivé, on le grise
    &:disabled {
        background-color: $bloc-background;
        border: 2px solid $bloc-background;
    }
    &:disabled, &:disabled .fas {
        color: $bloc-background;
    }
}
.myside2 {
    display: inline-flex;
    margin: auto  5px;
}

// pour bulle d'aide Tooltip
.simply{
    background-color: transparent !important;
    border : none;
    margin-left: 10px;
    padding: 0px;
}
.simply .fas {
    color: $color-button-entourage;
    font-size: 1.6em; 
}
.simply:hover .fas {
    color: $color-button-background-hover;
}







.mybtn .fas {
    background-color: $color-button-entourage;
    color: #ffffff;
    border-radius: 100px;
    padding: 10px;
    margin-left: 10px;
}

.mybtn {
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    background-color: transparent !important;
    border: 2px solid $color-button-entourage;
    border-radius: 100px;
    margin: 10px 0 10px 30px;
    color: $color-button-text !important;
    justify-content: center;
    padding: 10px;

    text-decoration: none;
}


.mybtn:hover .fas {
    background-color: $color-button-background-hover;
}