.bottom-navig {
    //max-width: 980px; commetné le 2508 allait pas ici https://localhost:3000/formation/etape11/243430/action-1517902/session-432178
    display: flex;
    justify-content: space-between;

    .elemenbottom-navigt:nth-child(1) {
      text-align: left;
    }
    .elemenbottom-navigt:nth-child(2) {
      text-align: right;
    }

    
    .bottom-navig-item {
      text-decoration: none;
    }

  .bottom-navig-item .fas {
    background-color: #FFFFFF;
    color: $color-liens-arrowSuivantPrecedent;
    font-size: 4em; 
    border-radius: 100px;
    padding: 4px 1px;
    margin-left: 10px;
  }

  .bottom-navig-item:hover .fas {
      color: $color-button-background-hover;
  }
}

.normal-navig-item .fas {
  background-color: #FFFFFF;
  color: $color-liens-arrowSuivantPrecedent;
  font-size: 1.4em; 
  border-radius: 50px;
  padding: 2px 10px 10px 0px;
  vertical-align: sub;

}
.normal-navig-item  {
    text-decoration: underline;
}
.normal-navig-item:hover  {

  color: $color-button-background-hover;
  cursor: pointer;
}
.normal-navig-item:hover .fas {
    color: $color-button-background-hover;
}

.disabled .fas {
  color: $bloc-background !important;
}