////////>SousMenu///////
.bg-red-200 {
  background-color: $color-alert-background;
  }
  .badged {
    margin-top: 10px;
    height: 20px;
  
  }
  .badged2 {
    padding-top: 12px; /* decalage hauteur texte */
    margin-left: 1rem;
    margin-right: 1rem;
  }
.text-red-600 {
  color: $color-alert-font;
}
/////
.xl\:-mr-6 {
  margin-right: -1.5rem;
}
.sm\:mr-8 {
  margin-right: 2rem;
}
.md\:mr-8 {
  margin-right: 2rem;
}
.mr-4 {
margin-right: 1rem;
}
////////Menu///////

.block {
  display: block;
}
.hidden {
  display: none !important;
}
.absolute {
  position: absolute;
}
.w-48 {
  width: 29rem;
}


.mt-4 {
  margin-top: 1rem;
}
.rounded-tr-base {
  border-top-right-radius: 0.5rem;
}
.rounded-tl-base {
  border-top-left-radius: 0.5rem;
}
.rounded-br-base {
  border-bottom-right-radius: 0.5rem;
}
.rounded-bl-base {
  border-bottom-left-radius: 0.5rem;
}

.bg-gray-50 {
  --bg-opacity: 1;
  background-color: $color-sous-menu-background;
  border: 1px $bloc-background solid;
  //border-top: 0px none;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: 8px 8px 12px #aaa;
  box-shadow: 5px 5px 5px #aaa;

}.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.w-full {
  width: 100%;
}
///////SubMenu////////
.mt-2 {
  margin-top: 0.5rem;
}
.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}
.text-xs {
  font-size: 0.75rem;
}
.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Barlow, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.font-semibold {
  font-weight: 600;
}
.mt-6 {
  margin-top: 1.5rem;
}
.flex {
  display: flex;
}
.justify-start {
  justify-content: flex-start;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
///////// Item ////////
 .text-gray-700 {
  --text-opacity: 1;
  color: #4a5568;
  color: rgba(74, 85, 104, var(--text-opacity));
}
.text-base-14 {
  font-size: 0.875rem;
}
.text-brand {
  --text-opacity: 1;
  color: #8F17FA;
  color: rgba(143, 23, 250, var(--text-opacity));
}
.hover\:text-brand:hover {
  --text-opacity: 1;
  color: #8F17FA;
  color: rgba(143, 23, 250, var(--text-opacity));
}

////////// Badge ////////////
.rounded-full {
  border-radius: 9999px;
}
.align-baseline {
  vertical-align: baseline;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}


.ml-auto {
  margin-left: auto;
}
.rounded-sm {
  border-radius: 0.125rem;
}
///

.font-bold {
  font-weight: 700;
}
