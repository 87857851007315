.footer {
  //position: sticky; bottom: 0; left: 0; right: 0  ;
  display: flex;
  justify-content: center;
  background-color: $bloc-background;
  margin-top : 20px;
  padding: 2rem 0 1.5rem;
}
  
.footer-text {
  margin: 0;
  padding: 0;
}