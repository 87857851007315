/*
Gris  BACKGROUND  #ececec
Jaune orange prononcé BTN SUIVANT, CHEVRON, BG MENU GAUCHE ACTIF  #fbba00
Rouge soft CONTOURS BTN, PICTOS BTN, POLICE BTN D’ACTION HOVER, MENU HOVER, CROIX MENU GAUCHE ELEMENT A CORRIGER   #ec6a69
Bleu foncé ECRITURES GENERALES #002144
Bleu soft BG AIDES #8baddc
Vert PICTO VALIDATION #2fc26d
*/

$color-aide-background : #8baddc;
$color-aide-alert : #ec6a69;

$ecriture-generales: #002144; // Bleu foncé CRITURES GENERALES #002144

$bloc-background : #ececec;

$color-button-entourage : #ec6a69; //rgb(236, 106, 105);
$color-button-background-hover: #004881;
$color-button-text :rgb(1, 50, 100);

$color-font-general: #002144 ;
$color-font-menu: #FFFFFF;
$color-background-menu: #000000;


$color-fond-top-menu: #0069B4;
$color-3: #21BBEF;

$color-liens: #002144; // leger diff mag mais pris sur copie ecran
$color-liensOn: #ec6a69; 
$color-liens-footer: #FABA00; // leger diff mag mais pris sur copie ecran  (au lieu de fbba00=
$color-liens-arrowMenuLeft: #FABA00; 
$color-liens-arrowSuivantPrecedent: #FABA00; 
$color-liens-footerOn: red;

$color-background-menu : #0069B4;
$color-sous-menu-background: #FFFFFF;

$color-alert-font: red; // pour alerte dans menu top, et aussi exclamation erreur menu left
$color-alert-background: #fed7d7;

$color-bord-gauche-bloc: red;

$color-picto-validation: #2fc26d;

$color-selection-orig-list: #fbba00; //#3282f7;
$color-selection-orig-list-selected: red;

$color-obligatoire : #d00;