@import "../SCSS/Variables.scss";

.faq-row-wrapper {
    .faq-title {
        border-bottom : none !important;
        margin-top: 2em;

        h2 {
            //font-size: larger;
            //color : orange !important;
            line-height: 0px; 
            color: rgb(153, 153, 153); 
            text-align: center; 
            padding: 20px 0px 15px;
            font-size: 1.8em;
        }
    }

    .faq-body {
        .faq-row {
            border-bottom : none !important;
            padding-bottom: 0px;

            .row-title {
                //color : red;
                font-size: 1.2em !important;
                background-color: #8baddc !important;
                //background: linear-gradient(45deg, #8baddc, #d8e5f7);
                border-radius: 15px !important; // 5
                border: 1px grey solid !important;
                padding: 8px 5px !important;
                font-weight: bold !important;
                margin: 0px !important;
            }

            .row-content {
                .row-content-text {
                    //color: green;
                    padding: 0 0.5em;
                    background-color: rgb(249, 249, 249);
                    border-radius: 5px;
                    margin: 0 12px;
                }
            }
        }
    }
}