.header {
    align-items: center;
    justify-content: space-between;
    //background-color: $bloc-background;
    background-color: var(--user-theme-color, $bloc-background); // va etre modifié en fonction du type d'utilisateur

    img {
       width: 11rem;
    }
    .logo-header-left {
        display:inline;
        position: absolute;
        left : 10px;
        top: 10px;
    }
    .titre-header {
        font-family: 'Barlow-SemiBold';
        padding-top: 0.4em;
        padding-bottom: 1em; //0.2 avant qu'on y colle le nom de l'org
        text-align: center;

        h1 {
            font-size: 1.7em;
            display : inline;
        }
        h2 {
            font-size: 1.2em;
            display : inline;
        }
    }
    .header-menu {
        z-index: 2;
        position: relative;
        display: flex;

        nav {
            display: flex;
            justify-content: space-around;
            width: 100%;
            ul { margin-bottom: 0 !important;} // utile sinon le reboot de bootstrap applique automatiquement 1 rem à ul

            a {
                display: inline-block; // utile de remplacer block par inline-block pour mettre le séparateur ::after ci dessous , du aussi faire <div style={{display:"inline-block"}} dans SousMenu
                padding: 5px 2px;

                font-size: 1.6rem;
                @media screen and (max-width: 500px) {
                    font-size: 1rem;
                }
            }

            .header-nav-item {
                text-decoration: none;
                margin-right: 0.5rem;
                //color: red !important;
            }
            li:not(:last-child)::after {
                content: ' | '; /* Contenu ajouté après chaque élément */
                color: #999; /* Couleur du pipe (facultatif, à ajuster selon vos préférences) */
                margin: 0 5px; /* Marge autour du pipe (facultatif, à ajuster selon vos préférences) */
              }

            .header-nav-itemOn {
                color: $color-liensOn !important;
                text-decoration: underline;
                margin-right: 0.5rem;
                cursor: pointer;

                border-color: red;            
                border-top: 2px solid;
                background-color: white;
            }
        
            .header-nav-item:hover {
                text-decoration: underline;
                color: $color-liensOn !important;
            }
        }
}
}