.cgv-conteneur {
  max-width: 1024px;
  //background-color: lightgrey;
  padding: 10px 10px;

  .addr_et_ct_carif {
    max-height: 110px;
  }

  p{
    padding-left: 10px;
    //margin-bottom: 0px
  }
  ul{
    list-style-type:disc;
  }
  .titre{
    margin : 0 50px;
    text-align: center;
    font-size: 2.4em;
 
    padding: 30px 0 40px;
    font-family: "Barlow-SemiBold";
  }
  .preambule{
    font-size: 2.2em;
    font-weight:bold;
  }
  .titre-art {
    background-color: $color-aide-background;
    //margin : 0 20px 10px 10px;
    margin : 20px 0 10px;
    font-weight:bold;
    font-size: 1.2em;
  }
  .sst-art {
    margin : 10px 20px 10px 10px;
    font-weight:bold;
    font-size: 1.1em;
  }
}