@import '../SCSS/Variables.scss';
@import '../SCSS/Header.scss';
@import '../SCSS/LeftMenu.scss';
@import '../SCSS/SousMenu.scss';
@import '../SCSS/Footer.scss';
@import '../SCSS/Buttons.scss';
@import '../SCSS/LiensPrecedentSuivant.scss';

@import '../SCSS/Singin.scss';
@import '../SCSS/FormationGen.scss';

@import '../SCSS/Table.scss';
@import '../SCSS/Certifs.scss';
@import '../SCSS/Cgv.scss';
@import '../SCSS/DetailFiche.scss';

/*
textes basiques : 15px,
 menu gauche + boutons :18px font-weight de 500
titres page : 19px
menus :21px font-weight 500 et sous menus 19px

inspiration : sportsee, page login : argent bank (p13)*/
//@import url('https://fonts.googleapis.com/css2?family=Barlow');
.tr {
  background-color: red;
}
@font-face {
  font-family: 'Barlow-Regular';
  src: url('../fonts/Barlow-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Barlow-SemiBold';
  src: url('../fonts/Barlow-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Barlow-Bold';
  src: url('../fonts/Barlow-Bold.ttf') format('truetype');
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 0.71rem;
}

body {
  box-sizing: border-box;
  cursor: default;
  //font-family: 'Wingdings', sans-serif;
  --bs-body-font-family: 'Barlow-Regular';
  color: $ecriture-generales !important;
  height: 100vh;
}

option {
  font-family: 'Barlow-Regular' !important; // sinon bootstrap mais inherit.... nok pour les select
  font-size: 1.2rem !important;
}
a {
    color: $ecriture-generales !important;
    text-decoration: none;
    //font-size: 1.4rem; commenté car trop gros dans les tab de resultats
    &:hover {
        text-decoration: underline;
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

b {font-weight: bold;}

.App {
  display:flex; // display  min-height:100vh  et flex-direction: pour prenne hauteur total.. marche uniquement si  le conteneur principal a flex: 1;
  min-height:100vh; 
  flex-direction: column;
}

.contenu-principal {
    flex: 1; // signifie qu'il occupe tout l'espace
  //width: 80%;
  margin: 0 auto;
  padding: 0 1px;
}
.form-select { // class bootstrap, pour éviter que le select en prenne toute la page
  width : auto !important;
}
.info-page-introuvable {
  height: 40rem;
  margin-left: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
}

.bloc-centre {
  text-align: center;
  padding-bottom: 2em;

  a{
    text-decoration: underline;
    font-weight: bold;
    display: block;
    padding-bottom: 1em;
  }
  h2 {
      font-family: 'Barlow-SemiBold';
  }
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-nav a, .main-nav .pipe {
  padding: 0 5px;
  color: $color-liens-footer !important;
}

.main-nav-item {
  text-decoration: none;
  margin-right: 0.5rem;
}
.main-nav-itemOn {
  color: $color-liensOn !important;
  text-decoration: underline;
  margin-right: 0.5rem;
  cursor: pointer;
}

.main-nav-item:hover {
  text-decoration: underline;
}

.message-info, .message-erreur-api, .message-info-important {
  //width: 100%;
  //min-width: 940px; //980px nok depasse dans l'accordéon a cause d'un padding left dans accordion-body... 30/08 commenté pour la modal "mot de passe oublié".. du coup mis cette propriété seule dans .message-info  en dessous (à 100%)... avoir..

  background-color: $color-aide-background;
  border-radius: 5px;
  padding: 5px;
  font-weight:bold;
  margin : 0 0 20px; //pas de marge supp ca fait un decalage dnas l'edition de form avec le menu de gauche
}

.message-info {
  //width:  fit-content; pas chouette dans l'édit des syn de certif
  min-width: 740px; //decommenté le 121223 (était à 940, utile pour fenetre intermediaire gestion appellations)
  margin-right: 5px;
}

.message-erreur-api, .message-info-important {
  background-color: $color-aide-alert;
  width: auto;
  font-size: 14px;
}
.message-info-important {
  margin : 0px;
}

.info-basic {
  background-color:azure;
  //margin-bottom: 11px; suppr pour les infos dans les sessions, à voir si pose problème pour les adresses ou les personnes
  border : 1px $bloc-background solid;
  border-radius: 5px;
  padding:5px;
  z-index: 1; // pour passé par dessus l'image de droite
}
.message-info2 {
  background-color:azure;
  margin-bottom: 11px;
  border : 1px $bloc-background solid;
  border-radius: 5px;
  padding:5px;
  margin: 0 1rem 1.5rem;
}
.debug {background-color:rgb(209, 213, 213)}




.conteneur-liste-selection {
  height: auto;
  margin: 1rem;

  .item, .item-principal {
    background-color: $color-selection-orig-list;
    margin: 1rem 0.2rem;
    border-radius: 3px;
    padding: 0.2rem 0.8rem;
    color: #FFFFFF;
    font-size: 1.1em;
  }
  .item-principal {
    //background-color: $color-selection-orig-list-selected;
    color :black;
    font-weight: bold;
  }

  .poubelle {
    margin-left: 0.9rem;
    cursor: pointer;
    //color: #FFFFFF;
    color: $color-button-entourage;
  }

  .far.fa-times-circle:hover {
    color: black;
  }
}

.picto-legende, .picto-legende2 { // par ex pour l'étoile du diplome principal dans la partie listes des diplomes certifications
  color : $color-button-entourage; 
  border-radius: 5px;
  background-color : rgb(232, 232, 232); 
  padding: 0 1px 2px;
  margin-left: 2px; /* ajouté pour  picto + de rech prof et certif, a voir si pas nok par ailleurs */
}
.picto-legende2 {
  color : rgb(1, 50, 100) !important;
}

.alert-depassement{
  color: $color-alert-font;
  padding-top : 5px;
  margin-bottom : 0px !important; //pour que ce soit pas trop espacé quand il y a l'info de dépassement bootstrap
}

.form-error{
  color: $color-alert-font;
}

.required .form-label:after { color: $color-obligatoire ; content: "*";  margin-left: 8px; top:7px; } /*position: absolute; https://www.ipgirl.com/12839/ajouter-un-asterisque-aux-champs-obligatoires-dans-bootstrap-3.html*/

// utile quand l'unput est inline :
.required-inline {
  display: inline !important;
  width: 90% !important;
} 
.required-inline-etoile {
  color:$color-obligatoire !important;  
  margin-left: 8px ; top:7px;
  display: inline;
} 
.text-obligatoire{
  color:$color-obligatoire;
}
.text-important{
  color: $color-alert-font;
  font-weight: bold;
  margin-bottom : 0px !important;
}

.form-label{ 
  //on rajoute des propiétés à cette class bottstrap
  font-weight: bold;
}
.contenu-ht-fixe-txt-form{
  height: 100px;
  overflow-y: scroll;
  border: 1px #ced4da solid;
  background-color: #E9ECEF;
}
.form-input-lg-short{
  width: 200px;
}
.inline-input {
  display: inline-table;
  padding-right: 10px;
  min-width: 200px;
}

/* La Position absolu : il nous permet de placer un block n'importe où sur la page.
* La Position fixe : c'est pareil que le Position absolu, mais cette fois le block reste toujours visible, même si on descend plus bas dans la page. C'est un peu comme le background-attachment:fixed.
* La Position relatif : c'est le plus compliqué des trois. En gros, ça nous permet de déplacer un block */
.image-presentation-short {
  position: absolute;
  right : 20px;
  top: 0px;

  .feature-presentation-short {
      max-height: 150px;
  }
}

	
.image-presentation-short-pro {
  position: absolute;
  right : 20px;
  top: 0px;
}

.image-presentation-short-pro::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height:100%;
  //background: -webkit-linear-gradient( bottom , #F5F5F5, rgba(255,255,255,0));
  //background: -webkit-linear-gradient(  bottom left , #F5F5F5, rgba(255,255,255,0));
  background: radial-gradient(circle 180px at 180px, rgba(255,255,255,0), #F5F5F5 );
  
  //background: linear-gradient(to top, #F5F5F5, rgba(255,255,255,0));
}

.fa-ban {
  color: red;
}
.fa-check {
  color: rgb(8, 228, 8);
  font-size: 15px;
}




